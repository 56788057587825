<template>
  <label class="input-text">
    <span v-if="label" class="label">{{ label }}</span>

    <span class="wrap">
      <input
        v-model="model"
        :placeholder="placeholder"
        :disabled="disabled"
        class="input"
        type="text"
        @keydown.enter="emit('enter')"
        @focus="emit('focus')"
        @blur="emit('blur')"
      />
      <BaseIcon v-if="icon" :name="icon" class="icon" @click="emit('enter')" />
    </span>
  </label>
</template>

<script lang="ts" setup>
const model = defineModel<string>();
const emit = defineEmits(['focus', 'blur', 'enter']);

defineProps<{
  label?: string;
  placeholder?: string;
  icon?: string;
  disabled?: boolean;
}>();
</script>

<style src="./Text.scss" scoped lang="scss"></style>
